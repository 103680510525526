import { useCallback, useEffect, useMemo, useState } from "react";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import { format } from "date-fns";
import { Box, Stack } from "@mui/system";
import {
  Alert,
  AlertTitle,
  Link,
  ListItem,
  ListItemText,
  Snackbar,
  SvgIcon,
  Typography,
} from "@mui/material";

import Iconify from "src/components/iconify";
import { usePopover } from "src/hooks/use-popover";
import { NotificationsPopover } from "./notifications-popover";
import { userApi } from "src/api/user";
import { paths } from "src/paths";

export const NotificationsButton = () => {
  const popover = usePopover();
  const [notifications, setNotifications] = useState([]);
  const [lastNotification, setLastNotification] = useState();
  const [player, setPlayer] = useState(null);

  const getNotification = useCallback(async () => {
    try {
      const response = await userApi.getNotification();

      setNotifications(response?.notifications);
    } catch (error) {
      console.error("error :", error);
    }
  }, []);

  const setReadNotification = useCallback(async (ids) => {
    try {
      await userApi.setReadNotification({ ids });
      handleStopNotification();
      getNotification();
    } catch (error) {
      console.error("error: ", error);
    }
  }, []);

  const unread = useMemo(() => {
    const unreadCount = notifications?.filter(
      (item) => item?.seen == false
    )?.length;
    const lastId = notifications?.at(-1)?.id;
    const prevLastId = localStorage.getItem("last_notification_id");
    localStorage.setItem("last_notification_id", lastId);
    const notSeenNotification = notifications?.filter(
      (item) => item?.seen == false
    )?.[0];
    if (unreadCount) {
      setLastNotification(notSeenNotification);
    } else {
      setLastNotification();
    }
    // if (lastId != prevLastId) {
    //   if (unreadCount) popover.handleOpen();
    // }
    return unreadCount;
  }, [notifications, player]);

  useEffect(() => {
    const interval = setInterval(() => {
      getNotification();
    }, 4000);
  }, []);

  const handleCloseNotification = () =>
    setReadNotification([lastNotification?.id]);

  const renderContent = (notification) => {
    const createdAt = format(
      new Date(notification?.created_at),
      "MMM dd, h:mm a"
    );
    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Link
            href={`${paths.dashboard.customers.index}/${notification?.client_id}`}
            underline="always"
            variant="body2"
          >
            {notification.client_name}
          </Link>
          <Typography
            sx={{
              ml: 2,
              color: notification?.seen ? "text.disabled" : "text.primary",
            }}
            variant="body2"
          >
            {notification.message}
          </Typography>
        </Box>
        <Typography color="text.secondary" variant="caption">
          {createdAt}
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          ref={popover.anchorRef}
          onClick={() => {
            popover.handleOpen();
            getNotification();
          }}
          sx={{ "&:hover": { color: "primary.main" } }}
        >
          <Badge color="error" badgeContent={unread}>
            <Iconify icon="line-md:bell-loop" width={26} />
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationsPopover
        anchorEl={popover.anchorRef.current}
        notifications={notifications}
        unread={unread}
        onClose={popover.handleClose}
        setReadNotification={setReadNotification}
        open={popover.open}
      />

      {lastNotification ? (
        <Snackbar
          open={unread > 0}
          // onClose={handleCloseNotification}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          sx={{ top: "94vh" }}
        >
          <Alert severity="info" onClose={handleCloseNotification}>
            <AlertTitle>New notification</AlertTitle>
            <Stack
              sx={{
                alignItems: "flex-start",
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: 1 }}
              >
                {renderContent(lastNotification)}
                {lastNotification?.seen ? null : (
                  <Tooltip title="Mark as read">
                    <IconButton
                      edge="end"
                      onClick={() => setReadNotification([lastNotification.id])}
                      size="small"
                      color="primary"
                    >
                      <SvgIcon>
                        <XIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
          </Alert>
        </Snackbar>
      ) : null}
    </>
  );
};
